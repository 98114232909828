// Font Family
$nunitosans: 'Nunito Sans', sans-serif;
$roboto: 'Roboto', sans-serif;

// Colors ---------------
$white: #ffffff;
$theme-color: #009FE3;
$theme-dark-color: #000000;
$theme-yellow: #000000;
$theme-yellow-dark: #494949;
$theme-blue: #494949;
$theme-blue-dark: #494949;
$body-text-color: #000000;
$body-text-color-grey: #000000;
$body-text-color-grey3: #000000;
$grey-bg: #ecf3f0;
$grey-bg-light: #f7f7f7;
$grey-bg-lighttwo: #f5f5f5;


// Heading Color
$heading-color: #009FE3;
$heading-color-black: #000000;

.kJVksy{
    background: $theme-color;
  }
  

// Soft color


// Responsive Variables
$xxxl: 'only screen and (min-width: 1600px) and (max-width: 1750px)';
$xxl: 'only screen and (min-width: 1400px) and (max-width: 1599px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg : 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';

body  .scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

body .scroll::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

body .scroll::-webkit-scrollbar-thumb
{
	background-color: $theme-color;
}

body::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	background-color: $theme-color;
}
